import { getItem } from '@/helpers/localstorage';
import { useGuest } from '@/context/guest';
import { ENABLE_ANNOUNCEMENT_MODE, ENABLE_QR_INVITATION } from '@/constants/feature-flags';

import useQueryString from './useQueryString';
import useGroupLink from './useGroupLink';

/**
 * Custom hook to determine is invitation or not
 * @return {string}
 */
function useInvitation() {
  const { getQueryValue } = useQueryString();
  const { isRegistered, isLoading } = useGuest();
  const isGroup = useGroupLink();
  const isInvitation = getQueryValue('type', '') === 'invitation';

   // if this link not group link, then return false
   if (isGroup) return true;

  if (!isInvitation) return false;

  // return false if this invitation doesn't have announcement feature
  if (!ENABLE_ANNOUNCEMENT_MODE) return true;

  // if guest info still in loading, we set default condition to true
  if (isLoading) return true;

  // IMPORTANT: we returning `false` if invitation are QR Code and user not registered anymore
  if (ENABLE_QR_INVITATION && !isRegistered) {
    return false;
  }

  const code = getQueryValue('code');
  const savedCode = (isGroup && getItem('guestCode')) || '';
  const finalCode = code || savedCode;

  // if guest has code and not registered, then return false
  if (finalCode && !isRegistered) {
    return false;
  }

  if (finalCode && isRegistered) {
    return true;
  }

  return isInvitation;
}

export default useInvitation;
